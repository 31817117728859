<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title font-size_60">
            <div>医药大健康领域</div>
            <div>营销管理SaaS系统</div>
          </h1>
          <p class="banner_desc font-size_20">
            S2P智能管理平台将基础的数据平台有效地结合移动终端开发，提供销售效能管理、分销渠道管理、流向数据管理、营销闭环管理及主数据管理等营销管理SaaS系统。
          </p>
        </div>
      </div>
      <div class="l_r_space t__space">
        <h2 class="nav__title">核心产品</h2>
        <p class="nav__desc" style="padding: 0.15rem 0 0.4rem 0">
          医药行业数字化闭环解决方案，支持多渠道营销、多维度管理的混合销售模式
        </p>
        <div class="product">
          <a-row :gutter="40" type="flex">
            <a-col
              class="mouse-style"
              style="padding-bottom: 15px"
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="6"
              :xxl="6"
              v-for="(item, index) in productList"
              :key="index"
              @click="toPage(item.path_name)"
            >
              <div
                class="product-info_item"
                @mouseover="mouseOver(index)"
                @mouseleave="mouseLeave(index)"
              >
                <img
                  :src="item.img_url"
                  :alt="item.title"
                  class="view_width-full"
                />
                <div
                  class="product-info__hover-animation"
                  :style="{ opacity: item.is_animation ? 0.8 : 0 }"
                >
                  <div
                    class="product-info__hover-animation product-info__hover-animation_box"
                    :style="{
                      opacity: item.is_animation ? 0.8 : 0,
                      transform: item.is_animation
                        ? 'translateY(0%)'
                        : 'translateY(-20%)',
                    }"
                  >
                    <div
                      class="icon_box"
                      :id="'icon_box-hover_' + index"
                      @mouseover="mouseOverIcon(index)"
                      @mouseleave="mouseLeaveIcon(index)"
                    >
                      <a-icon type="double-right" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="text_left">
                <h3 class="col_title font-size_26">{{ item.title }}</h3>
                <p class="col_desc font-size_16">{{ item.desc }}</p>
                <p class="col_info font-size_16">{{ item.info }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space bgc_f4fbff">
        <h2 class="nav__title">多系统数据连通</h2>
        <div style="padding: 1rem 0 0.8rem 0">
          <a-row type="flex" :gutter="40">
            <a-col
              style="padding-bottom: 15px"
              :xs="12"
              :sm="12"
              :md="6"
              :lg="6"
              :xl="6"
              :xxl="6"
              v-for="(item, index) in moreDada"
              :key="index"
            >
              <img
                :src="item.img_url"
                :alt="item.title"
                style="width: 100%; max-width: 215px"
              />
              <h3 class="industry_title b_b_space font-size_26">
                {{ item.title }}
              </h3>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import product_1 from "@/assets/img/png/product_1.png";
import product_2 from "@/assets/img/png/product_2.png";
import product_3 from "@/assets/img/png/product_3.png";
import product_4 from "@/assets/img/png/product_4.png";
import product_5 from "@/assets/img/png/product_5.png";
import product_6 from "@/assets/img/png/product_6.png";
import product_7 from "@/assets/img/png/product_7.png";
import product_8 from "@/assets/img/png/product_8.png";
import product_9 from "@/assets/img/png/product_9.png";
import product_10 from "@/assets/img/png/product_10.png";
import product_11 from "@/assets/img/png/product_12.png";
import product_12 from "@/assets/img/png/product_11.png";
export default {
  name: "salesManagement",
  beforeCreate() {},
  components: { Footer, HeaderMenu },
  data() {
    return {
      productList: [
        {
          title: "主数据平台",
          desc: "帮助企业沉淀分析主数据，可提供主数据清洗服务及覆盖率分析",
          info:
            "帮助企业清洗沉淀建设主数据系统，依托强大的S2P平台行业数据库，可清洗主数据标准名称及省市区地址等各类标签字段，提高企业客户真实性。",
          img_url: product_1,
          path_name: "masterDataPlatform",
        },
        {
          title: "辖区与指标系统",
          desc: "销售辖区管理、销售指标下达及达成计算",
          info:
            "科学合理的组织架构及辖区指标管理系统。能适应各种营销模式的辖区管理及指标下达，根据市场规模合理布局个辖区人员配置，及时调整绩效指标",
          img_url: product_2,
          path_name: "Jurisdiction",
        },
        {
          title: "销讯通·行为管理",
          desc: "销售行为管理、客户沟通过程管理、行为绩效分析",
          info:
            "销讯通销售效能管理系统是一款精心打造，为医药企业专属定制的CRM与SFE管理系统。也是销售总监、大区经理、地区经理、招商经理和医药代表最好的移动管理助手！",
          img_url: product_3,
          path_name: "p2ps",
        },
        {
          title: "彩蝶•流向管理",
          desc: "流向直连采集、流向自动归属、智能销售分析",
          info:
            "彩蝶•流向管家是一款专门进行企业流向数据清洗、查询、统计汇总及销售业绩核算的平台。可实现对终端进货、商业流通、预警等分析的功能，辅助企业营销管理层进行绩效管理、决策及营销费用的结算等。S2P独有的强大的爬虫技术，为系统提供更深度的数据抓取。",
          img_url: product_4,
          path_name: "flowSteward",
        },
        // {
        //   title: "向日葵•结算系统",
        //   desc: "标签",
        //   info:
        //     "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        //   img_url: product_5,
        //   path_name: "sunflower",
        // },
        {
          title: "芒哥•合规管理",
          desc: "服务商管理、合规报告采集",
          info:
            "芒哥·合规管理是基于”代表备案制度”体系下的合规证据链管理系统，集云端、移动端(微信小程序) 一体化的应用型工具。采集与汇总推广服务合规信息，为医药营销团队提供可视、真实的、数字化的合规证据链。",
          img_url: product_6,
          path_name: "mg",
        },  {
              title: "财猫·订单管理",
              desc: "商业回款管理、订单全流程管理",
              info:
                  "满足工业企业、商业公司、推广服务等不同企业在销售、采购、入库、发货等场景下的数字化管理需求，提供基础数据支撑以实现费用结算的便捷性和准确性。",
              img_url: product_12,
              path_name: "cm",
          },
          {
              title: "虎珀·费用管理",
              desc: "一体化结算流程、多元化结算模式",
              info:
                  "适用于工业下游费用结算和各级推广服务商结算场景。智能算法和数据处理技术，显著提高财务和运营工作效率。它以工业发货、终端流向和销售订单为基础数据源，与S2P的其他产品模块深度集成，实现了业务财务一体化、可视化。",
              img_url: product_11,
              path_name: "hp",
          },

      ],
      moreDada: [
        { title: "PC端", img_url: product_7 },
        { title: "android端", img_url: product_8 },
        { title: "iOS端", img_url: product_9 },
        { title: "微信/钉钉", img_url: product_10 },
      ],
    };
  },
  created() {},
  methods: {
    toPage(name) {
      if (name == "sunflower") {
        return;
      }
      this.$router.push({
        name,
      });
    },
    mouseOver(index) {
      this.$set(this.productList[index], "is_animation", true);
    },
    mouseLeave(index) {
      this.$set(this.productList[index], "is_animation", false);
    },
    mouseOverIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#000";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "#fff";
    },
    mouseLeaveIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#fff";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "rgba(88, 95, 105, 0.3)";
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.col_title {
  padding-top: 0.2rem;
  color: $color-darkBlue;
  margin-bottom: 0;
}
.col_desc {
  color: #72c0f1;
  margin-bottom: 0;
}
.col_info {
  color: $color-gray;
  padding-top: 0.2rem;
  word-break: break-all;
  margin-bottom: 0;
}
.industry_title {
  padding-top: 0.6rem;
}
.banner {
  h1,
  p {
    margin-bottom: 0;
  }
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/product_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  color: $color-white;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.9rem;
    left: 13.5%;

    .banner_title {
      color: $color-white;
    }
    .banner_desc {
      width: 3.84rem;
      padding-top: 0.45rem;
    }
  }
}
.product-info_item:hover,
.product-info_item::after,
.product-info_item::before {
  transition: all 0.5s ease;
}
.product-info_item {
  position: relative;
  .product-info__hover-animation {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease;
    background-color: rgba(88, 95, 105, 0.3) !important;
    box-shadow: 0 5px 10px -6px $color-midleGray;
  }
  .product-info__hover-animation_box {
    height: 115%;
    .icon_box {
      width: 0.4rem;
      height: 0.4rem;
      background-color: rgba(88, 95, 105, 0.3);
      border: 1px solid $color-white;
      position: absolute;
      top: 42.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
    }
  }
}
</style>
